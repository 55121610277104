/* eslint-disable react/display-name */
import Avatar from "@/components/common/Avatar";
import BoxCard from "@/components/common/BoxCard";
import EditorialOfficeName from "@/components/common/EditorialOffice/Name";
import EditorialOfficeStatusIcon from "@/components/common/EditorialOffice/StatusIcon";
import FlagImage from "@/components/common/FlagImage";
import HelpTooltip from "@/components/common/HelpTooltip";
import ParsedHTML from "@/components/common/ParsedHTML";
import SelectAdvanced from "@/components/common/SelectAdvanced";
import SocialMediaIcon from "@/components/common/SocialMediaIcon";
import StatusIcon from "@/components/common/StatusIcon";
import Table from "@/components/common/Table";
import { updateProfile } from "@/components/withAuthentication";
import useIsMobile from "@/hooks/useIsMobile";
import { mutateSession } from "@/hooks/useUser";
import {
  AccreditationTypes,
  ApplicationListStatuses,
} from "@/types/accreditation";
import { AccreditationTypeFields } from "@/types/event";
import { SocialMedia } from "@/types/socialMedia";
import { UserInvitationStatuses } from "@/types/user";
import {
  getAccreditationPhoto,
  getAccreditationStatusColor,
} from "@/utils/accreditation";
import { groupArrayBy } from "@/utils/helpers";
import { useBreakpointValue } from "@chakra-ui/media-query";
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  Spinner,
  Stack,
  Link as StyledLink,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
  Wrap,
  useDisclosure,
} from "@chakra-ui/react";
import { useSession } from "next-auth/client";
import { useTranslation } from "next-i18next";
import getConfig from "next/config";
import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";
import { Eye, Mail, UserPlus, UserX } from "react-feather";
import Jump from "react-reveal/Jump";
import useSWR from "swr";
import AccreditationModal from "./AccreditationModal";
import AccreditationStatus from "./AccreditationStatus";
import AccreditationTypeName from "./AccreditationTypeName";

const { publicRuntimeConfig } = getConfig();

const Applications = ({
  list = false,
  eventId,
  status = ApplicationListStatuses.PENDING,
}: {
  list?: boolean;
  eventId?: number;
  status?: ApplicationListStatuses;
}): React.ReactElement => {
  const router = useRouter();
  const { t } = useTranslation("common");
  const [session] = useSession();
  const [selectedId, setSelectedId] = useState();
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const accreditationModal = useDisclosure();
  const isMobile = useIsMobile();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const tabsOptions = useMemo(
    () => [
      {
        value: 0,
        label: t("events.pendingAccreditations"),
      },
      {
        value: 1,
        label: t("events.grantedAccreditations"),
      },
      {
        value: 2,
        label: t("other"),
      },
    ],
    []
  );

  const openAccreditationModal = (id) => {
    accreditationModal.onOpen();
    setSelectedId(id);
  };

  const closeAccreditationModal = () => {
    accreditationModal.onClose();
    setSelectedId(undefined);
  };

  const { data, error } = useSWR(
    eventId
      ? `/accreditation/list/${eventId}/${status}`
      : "/events/applications"
  );
  const isLoading = !data && !error;

  const { data: grantedApplications } = useSWR(
    eventId && !list
      ? `/accreditation/list/${eventId}/${ApplicationListStatuses.GRANTED}`
      : null
  );

  const { data: otherApplications } = useSWR(
    eventId && !list
      ? `/accreditation/list/${eventId}/${ApplicationListStatuses.OTHER}`
      : null
  );

  const typesWithStatus = useMemo(() => {
    const allApplications = data?.concat(
      grantedApplications,
      otherApplications
    );

    const typesWithStatus = allApplications?.map((application) => ({
      status: application?.status,
      type: application?.types?.[0],
    }));

    const groupedTypesWithStatus = groupArrayBy(
      typesWithStatus,
      (typewithStatus) => typewithStatus?.type?.id
    );

    const groupedTypesWithStatusArray = Object.values(
      groupedTypesWithStatus || {}
    );

    return groupedTypesWithStatusArray?.map((typeWithStatus: Array<any>) => ({
      type: typeWithStatus?.[0]?.type,
      pending: typeWithStatus?.filter((status) => status?.status === "pending")
        ?.length,
      canceledAndRejected: typeWithStatus?.filter(
        (status) =>
          status?.status === "canceled" || status?.status === "rejected"
      )?.length,
      incomplete: typeWithStatus?.filter(
        (status) => status?.status === "incomplete"
      )?.length,
      granted: typeWithStatus?.filter((status) => status?.status === "granted")
        ?.length,
    }));
  }, [data, grantedApplications, otherApplications]);

  // TODO: Exctract column components
  const columns = useMemo(
    () => [
      {
        Header: t("users.fullName"),
        accessor: ({ firstName, lastName }) => `${firstName} ${lastName}`,
        Cell: ({ row }) => {
          const accreditationPhoto = getAccreditationPhoto(
            row?.original?.fields
          );

          return (
            <Flex align="center">
              <FlagImage mr={2} language={row.original.user.profile?.locale} />
              <Avatar
                mr={2}
                src={
                  row?.original?.user?.profile?.picture || accreditationPhoto
                    ? `/uploads/${
                        accreditationPhoto ??
                        row?.original?.user?.profile?.picture
                      }`
                    : null
                }
                name={`${row.original.firstName} ${row.original.lastName}`}
              />
              <Text whiteSpace={{ base: "nowrap", md: "normal" }}>
                {row.original.firstName} {row.original.lastName}
              </Text>
            </Flex>
          );
        },
      },
      {
        Header: t("editorialOffice.editorialOffice"),
        accessor: ({ editorialOffice }) =>
          editorialOffice?.name || "freelancer",
        Cell: ({ row }) => {
          let avatarSrc = null;

          const accreditationPhoto = getAccreditationPhoto(
            row?.original?.fields
          );

          if (!row?.original?.editorialOffice?.name)
            avatarSrc =
              row?.original?.user?.profile?.picture || accreditationPhoto
                ? `/uploads/${
                    accreditationPhoto ?? row?.original?.user?.profile?.picture
                  }`
                : null;
          if (row?.original?.editorialOffice?.picture)
            avatarSrc = `/uploads/${row?.original?.editorialOffice?.picture}`;

          return (
            <Flex align="center">
              {row.original.editorialOffice?.officeRequest?.type ===
                "request" &&
              row.original.editorialOffice?.officeRequest?.status ===
                UserInvitationStatuses.REJECTED ? (
                <Flex alignItems="center">
                  <Avatar
                    src={
                      row?.original?.user?.profile?.picture ||
                      accreditationPhoto
                        ? `/uploads/${
                            accreditationPhoto ??
                            row?.original?.user?.profile?.picture
                          }`
                        : null
                    }
                    name={`${row?.original?.firstName} ${row?.original?.lastName}`}
                    size="sm"
                    mr={2}
                  />
                  <VStack spacing={0} align="left">
                    <Text>
                      {t("events.freelancer")}
                      <EditorialOfficeStatusIcon
                        editorialOffice={row?.original?.editorialOffice}
                      />
                      <StatusIcon
                        status="editorialOffice.accreditationInformation.officeRejectedInvitation"
                        icon={UserX}
                        ml={0}
                        color="red.500"
                        _hover={{ color: "red.800" }}
                      />
                    </Text>
                    <EditorialOfficeName
                      editorialOffice={row?.original?.editorialOffice}
                    />
                  </VStack>
                </Flex>
              ) : row.original.editorialOffice?.officeRequest?.type ===
                  "request" &&
                row.original.editorialOffice?.officeRequest?.status ===
                  UserInvitationStatuses.WAITING ? (
                <Flex alignItems="center">
                  <Avatar
                    src={
                      row?.original?.user?.profile?.picture ||
                      accreditationPhoto
                        ? `/uploads/${
                            accreditationPhoto ??
                            row?.original?.user?.profile?.picture
                          }`
                        : null
                    }
                    name={`${row?.original?.firstName} ${row?.original?.lastName}`}
                    size="sm"
                    mr={2}
                  />
                  <VStack spacing={0} align="left">
                    <Text>
                      {t("events.freelancer")}
                      <EditorialOfficeStatusIcon
                        editorialOffice={row?.original?.editorialOffice}
                      />
                      <StatusIcon
                        status="editorialOffice.accreditationInformation.officeHasGotInvitation"
                        icon={UserPlus}
                        ml={0}
                        color="green.500"
                        _hover={{ color: "green.800" }}
                      />
                    </Text>
                    <EditorialOfficeName
                      editorialOffice={row?.original?.editorialOffice}
                    />
                  </VStack>
                </Flex>
              ) : (
                <Flex align="center">
                  <Avatar
                    src={avatarSrc}
                    size="sm"
                    mr={2}
                    name={
                      row?.original?.editorialOffice?.name
                        ? row?.original?.editorialOffice?.name
                        : `${row?.original?.firstName} ${row?.original?.lastName}`
                    }
                    opacity={
                      row?.original?.editorialOffice?.name &&
                      !row?.original?.editorialOffice?.activated
                        ? "50%"
                        : undefined
                    }
                  />
                  <EditorialOfficeName
                    editorialOffice={row?.original?.editorialOffice}
                  >
                    {row?.original?.editorialOffice?.name
                      ? row?.original?.editorialOffice?.name
                      : t("events.freelancer")}
                  </EditorialOfficeName>
                  <EditorialOfficeStatusIcon
                    editorialOffice={row?.original?.editorialOffice}
                  />
                </Flex>
              )}
            </Flex>
          );
        },
      },
      {
        Header: t("events.accreditationType"),
        accessor: ({ types }) =>
          types?.[0]?.default
            ? t(`events.accreditationTypes.${types?.[0]?.name.en}`)
            : `${
                types?.[0]?.name[session?.user?.profile?.locale] ??
                types?.[0]?.name.en ??
                types?.[0]?.name[Object.keys(types?.[0]?.name)[0]]
              }`,
        Cell: ({
          row: {
            original: { types },
          },
        }) => {
          return types?.map((type) => {
            return (
              <SimpleGrid key={type.id} mr={4}>
                <Text>
                  {type?.default
                    ? t(`events.accreditationTypes.${type.name.en}`)
                    : type.name[session?.user?.profile?.locale] ??
                      type.name.en ??
                      type.name[Object.keys(type.name)[0]]}
                </Text>
                {types?.length > 1 && <Text>{type.location.name}</Text>}
              </SimpleGrid>
            );
          });
        },
      },
      {
        id: "action",
        Header: t("events.accreditation"),
        accessor: "id",
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <Flex>
            <AccreditationStatus
              accreditationId={row.original.id}
              eventId={eventId}
              status={row.original.status}
              openAccreditationPreview
              inTable
            />
            <Flex justifyContent="flex-end" ml={1} fontSize="20px">
              <Tooltip hasArrow label={t("preview")} placement="top">
                <StyledLink
                  lineHeight="1"
                  p="1"
                  onClick={() => openAccreditationModal(value)}
                >
                  <Icon as={Eye} />
                </StyledLink>
              </Tooltip>
            </Flex>
          </Flex>
        ),
      },
    ],
    [session]
  );

  const moreAccreditationNumber = (data || [])?.length - 5 || 0;
  const MoreButton = useMemo(
    () =>
      moreAccreditationNumber > 0 ? (
        <Button
          mr={4}
          mt={1}
          size={"sm"}
          onClick={async () => {
            setIsMoreLoading(true);
            await updateProfile({ event: eventId });
            await mutateSession();
            router.push("/events/applications");
          }}
          isLoading={isMoreLoading}
        >
          {`${t("more")} (${moreAccreditationNumber})`}
        </Button>
      ) : undefined,
    [eventId, moreAccreditationNumber, isMoreLoading]
  );

  const dataToMap = useMemo(
    () => (data?.length > 10 ? data?.slice(0, 10) : data),
    [data]
  );

  return (
    <>
      {!list && (
        <Flex flexDirection={"column"}>
          <Flex justifyContent="space-between" alignItems="center" mb={1}>
            <Heading as="h2" size="lg" mb={0}>
              <Flex align="center">
                {t("events.accreditationApplications")}
                <HelpTooltip label="events.heading" />
              </Flex>
            </Heading>
            <Link
              href={`${publicRuntimeConfig.basePath}/app-api/accreditation/list/${eventId}/export`}
              download
            >
              <Button>{t("exportExcel")}</Button>
            </Link>
          </Flex>
          <Wrap
            spacing={5}
            mb={5}
            mt={3}
            direction={{ base: "column", md: "row" }}
          >
            {typesWithStatus?.map((type) => (
              <Flex
                key={type?.type?.id}
                align="center"
                direction="column"
                gap={1}
              >
                <Text fontWeight="bold" textAlign="center">
                  {type?.type?.default
                    ? t(`events.accreditationTypes.${type?.type?.name.en}`)
                    : type?.type?.name[session?.user?.profile?.locale] ||
                      type?.type?.name.en ||
                      type?.type?.name[Object?.keys(type?.type?.name)?.[0]]}
                </Text>
                <HStack>
                  <StatusTypeAmountBox
                    type={AccreditationTypes.PENDING}
                    amount={type?.pending}
                  />
                  <StatusTypeAmountBox
                    type={AccreditationTypes.GRANTED}
                    amount={type?.granted}
                  />
                  <StatusTypeAmountBox
                    type={AccreditationTypes.REJECTED}
                    amount={type?.canceledAndRejected}
                  />
                  <StatusTypeAmountBox
                    type={AccreditationTypes.INCOMPLETE}
                    amount={type?.incomplete}
                  />
                </HStack>
              </Flex>
            ))}
          </Wrap>
        </Flex>
      )}
      <Box position="relative">
        {list ? (
          <>
            {isLoading && (
              <Center>
                <Spinner />
              </Center>
            )}
            {!isLoading && data?.length > 0 && (
              <>
                <Box d={{ base: "none", md: "block" }}>
                  <Table
                    columns={columns}
                    data={data?.slice(0, 5) || []}
                    searchBar={false}
                    pagination={false}
                    moreButton={MoreButton}
                  />
                </Box>
                <SimpleGrid
                  columns={1}
                  spacing={3}
                  d={{ base: "grid", md: "none" }}
                >
                  {dataToMap?.map((row) => {
                    const accreditationPhoto = getAccreditationPhoto(
                      row?.fields
                    );

                    return (
                      <BoxCard p={0} key={row?.id}>
                        <Flex
                          align="center"
                          justify="space-between"
                          p="1.25rem"
                          borderBottomColor="light.50"
                          borderBottomWidth="1px"
                        >
                          <Flex align="center">
                            <Avatar
                              src={
                                row?.user?.profile?.picture ||
                                accreditationPhoto
                                  ? `/uploads/${
                                      accreditationPhoto ??
                                      row?.user?.profile?.picture
                                    }`
                                  : null
                              }
                              name={`${row?.firstName} ${row?.lastName}`}
                              size="md"
                              mr={3}
                              alignSelf="start"
                            />
                            <Box>
                              <Flex wrap="wrap">
                                <Text fontWeight="500" mr={2}>
                                  {row?.firstName} {row?.lastName}
                                </Text>
                                <Box
                                  w="16px"
                                  h="16px"
                                  position="relative"
                                  mr={2}
                                  mt="2px"
                                >
                                  <FlagImage
                                    mr={2}
                                    language={row?.user.profile?.locale}
                                  />
                                </Box>
                              </Flex>
                              <Text color="text.500" fontSize="xs">
                                {row.editorialOffice?.officeRequest?.type ===
                                  "request" &&
                                row.editorialOffice?.officeRequest?.status ===
                                  UserInvitationStatuses.REJECTED ? (
                                  <Flex alignItems="center">
                                    <VStack spacing={0} align="left">
                                      <Text>
                                        {t("events.freelancer")}
                                        <EditorialOfficeStatusIcon
                                          editorialOffice={row?.editorialOffice}
                                        />
                                        <StatusIcon
                                          status="editorialOffice.accreditationInformation.officeRejectedInvitation"
                                          icon={UserX}
                                          ml={0}
                                          color="red.500"
                                          _hover={{ color: "red.800" }}
                                        />
                                      </Text>
                                      <EditorialOfficeName
                                        editorialOffice={row?.editorialOffice}
                                      />
                                    </VStack>
                                  </Flex>
                                ) : row.editorialOffice?.officeRequest?.type ===
                                    "request" &&
                                  row.editorialOffice?.officeRequest?.status ===
                                    UserInvitationStatuses.WAITING ? (
                                  <Flex alignItems="center">
                                    <VStack spacing={0} align="left">
                                      <Text>
                                        {t("events.freelancer")}
                                        <EditorialOfficeStatusIcon
                                          editorialOffice={row?.editorialOffice}
                                        />
                                        <StatusIcon
                                          status="editorialOffice.accreditationInformation.officeHasGotInvitation"
                                          icon={UserPlus}
                                          ml={0}
                                          color="green.500"
                                          _hover={{ color: "green.800" }}
                                        />
                                      </Text>
                                      <EditorialOfficeName
                                        editorialOffice={row?.editorialOffice}
                                      />
                                    </VStack>
                                  </Flex>
                                ) : (
                                  <>
                                    <EditorialOfficeName
                                      editorialOffice={row?.editorialOffice}
                                    >
                                      {row?.editorialOffice?.name
                                        ? row?.editorialOffice?.name
                                        : t("events.freelancer")}
                                    </EditorialOfficeName>
                                    <EditorialOfficeStatusIcon
                                      editorialOffice={row?.editorialOffice}
                                    />
                                  </>
                                )}
                              </Text>
                            </Box>
                          </Flex>
                        </Flex>
                        <Flex p="1.25rem" justifyContent="space-between">
                          <AccreditationStatus
                            accreditationId={row.id}
                            eventId={eventId}
                            status={row.status}
                            openAccreditationPreview
                          />
                          <Flex
                            justifyContent="flex-end"
                            ml={1}
                            fontSize="20px"
                          >
                            <Tooltip
                              hasArrow
                              label={t("preview")}
                              placement="top"
                            >
                              <StyledLink
                                lineHeight="1"
                                p="1"
                                onClick={() => openAccreditationModal(row.id)}
                              >
                                <Icon as={Eye} />
                              </StyledLink>
                            </Tooltip>
                          </Flex>
                        </Flex>
                      </BoxCard>
                    );
                  })}
                </SimpleGrid>
                {isMobile && data?.length > 10 && (
                  <Button
                    mt={3}
                    size="sm"
                    isLoading={isMoreLoading}
                    onClick={async () => {
                      setIsMoreLoading(true);
                      await updateProfile({ event: eventId });
                      await mutateSession();
                      router.push("/events/applications");
                    }}
                  >
                    {t("more")} ({data?.length - 10})
                  </Button>
                )}
              </>
            )}
            {!isLoading && data?.length === 0 && (
              <Text
                textAlign="center"
                fontWeight="500"
                py={2}
                bg={{ base: "white", md: "transparent" }}
                borderRadius={{ base: ".375rem", md: 0 }}
              >
                {t("noResultsFound")}
              </Text>
            )}
          </>
        ) : (
          <BoxCard
            p={0}
            w="100%"
            bg={{ base: "transparent", md: "white" }}
            boxShadow={{ base: "none", md: "0 3px 20px rgba(0,0,0,0.04)" }}
          >
            {isMobile && (
              <Box width={"100%"} mb={5}>
                <SelectAdvanced
                  options={tabsOptions}
                  onChange={(selectedValue) =>
                    setSelectedTabIndex(selectedValue?.value)
                  }
                  value={tabsOptions?.find(
                    (c) => selectedTabIndex === c?.value
                  )}
                  lg
                  isClearable={false}
                />
              </Box>
            )}
            <Tabs
              variant={useBreakpointValue({ base: "buttons", md: "solid" })}
              isLazy
              onChange={(index) => setSelectedTabIndex(index)}
              index={selectedTabIndex}
            >
              {!isMobile && (
                <TabList>
                  <Tab>{t("events.pendingAccreditations")}</Tab>
                  <Tab>{t("events.grantedAccreditations")}</Tab>
                  <Tab>{t("other")}</Tab>
                </TabList>
              )}
              <TabPanels>
                <TabPanel>
                  <Box d={{ base: "none", md: "block" }}>
                    <Table columns={columns} data={data || []} />
                  </Box>
                  <SimpleGrid
                    columns={1}
                    spacing={3}
                    d={{ base: "grid", md: "none" }}
                  >
                    {data?.map((row) => {
                      const accreditationPhoto = getAccreditationPhoto(
                        row?.fields
                      );

                      return (
                        <BoxCard p={0} key={row?.id}>
                          <Flex
                            align="center"
                            justify="space-between"
                            p="1.25rem"
                            borderBottomColor="light.50"
                            borderBottomWidth="1px"
                          >
                            <Flex align="center">
                              <Avatar
                                src={
                                  row?.user?.profile?.picture ||
                                  accreditationPhoto
                                    ? `/uploads/${
                                        accreditationPhoto ??
                                        row?.user?.profile?.picture
                                      }`
                                    : null
                                }
                                name={`${row?.firstName} ${row?.lastName}`}
                                size="md"
                                mr={3}
                                alignSelf="start"
                              />
                              <Box>
                                <Flex wrap="wrap">
                                  <Text fontWeight="500" mr={2}>
                                    {row?.firstName} {row?.lastName}
                                  </Text>
                                  <Box
                                    w="16px"
                                    h="16px"
                                    position="relative"
                                    mr={2}
                                    mt="2px"
                                  >
                                    <FlagImage
                                      mr={2}
                                      language={row?.user.profile?.locale}
                                    />
                                  </Box>
                                </Flex>
                                <Text color="text.500" fontSize="xs">
                                  {row.editorialOffice?.officeRequest?.type ===
                                    "request" &&
                                  row.editorialOffice?.officeRequest?.status ===
                                    UserInvitationStatuses.REJECTED ? (
                                    <Flex alignItems="center">
                                      <VStack spacing={0} align="left">
                                        <Text>
                                          {t("events.freelancer")}
                                          <EditorialOfficeStatusIcon
                                            editorialOffice={
                                              row?.editorialOffice
                                            }
                                          />
                                          <StatusIcon
                                            status="editorialOffice.accreditationInformation.officeRejectedInvitation"
                                            icon={UserX}
                                            ml={0}
                                            color="red.500"
                                            _hover={{ color: "red.800" }}
                                          />
                                        </Text>
                                        <EditorialOfficeName
                                          editorialOffice={row?.editorialOffice}
                                        />
                                      </VStack>
                                    </Flex>
                                  ) : row.editorialOffice?.officeRequest
                                      ?.type === "request" &&
                                    row.editorialOffice?.officeRequest
                                      ?.status ===
                                      UserInvitationStatuses.WAITING ? (
                                    <Flex alignItems="center">
                                      <VStack spacing={0} align="left">
                                        <Text>
                                          {t("events.freelancer")}
                                          <EditorialOfficeStatusIcon
                                            editorialOffice={
                                              row?.editorialOffice
                                            }
                                          />
                                          <StatusIcon
                                            status="editorialOffice.accreditationInformation.officeHasGotInvitation"
                                            icon={UserPlus}
                                            ml={0}
                                            color="green.500"
                                            _hover={{ color: "green.800" }}
                                          />
                                        </Text>
                                        <EditorialOfficeName
                                          editorialOffice={row?.editorialOffice}
                                        />
                                      </VStack>
                                    </Flex>
                                  ) : (
                                    <>
                                      <EditorialOfficeName
                                        editorialOffice={row?.editorialOffice}
                                      >
                                        {row?.editorialOffice?.name
                                          ? row?.editorialOffice?.name
                                          : t("events.freelancer")}
                                      </EditorialOfficeName>
                                      <EditorialOfficeStatusIcon
                                        editorialOffice={row?.editorialOffice}
                                      />
                                    </>
                                  )}
                                </Text>
                              </Box>
                            </Flex>
                          </Flex>
                          <Flex p="1.25rem" justifyContent="space-between">
                            <AccreditationStatus
                              accreditationId={row.id}
                              eventId={eventId}
                              status={row.status}
                              openAccreditationPreview
                            />
                            <Flex
                              justifyContent="flex-end"
                              ml={1}
                              fontSize="20px"
                            >
                              <Tooltip
                                hasArrow
                                label={t("preview")}
                                placement="top"
                              >
                                <StyledLink
                                  lineHeight="1"
                                  p="1"
                                  onClick={() => openAccreditationModal(row.id)}
                                >
                                  <Icon as={Eye} />
                                </StyledLink>
                              </Tooltip>
                            </Flex>
                          </Flex>
                        </BoxCard>
                      );
                    })}
                  </SimpleGrid>
                </TabPanel>
                <TabPanel>
                  <Box d={{ base: "none", md: "block" }}>
                    <Table columns={columns} data={grantedApplications || []} />
                  </Box>
                  <SimpleGrid
                    columns={1}
                    spacing={3}
                    d={{ base: "grid", md: "none" }}
                  >
                    {grantedApplications?.map((row) => {
                      const accreditationPhoto = getAccreditationPhoto(
                        row?.fields
                      );

                      return (
                        <BoxCard p={0} key={row?.id}>
                          <Flex
                            align="center"
                            justify="space-between"
                            p="1.25rem"
                            borderBottomColor="light.50"
                            borderBottomWidth="1px"
                          >
                            <Flex align="center">
                              <Avatar
                                src={
                                  row?.user?.profile?.picture ||
                                  accreditationPhoto
                                    ? `/uploads/${
                                        accreditationPhoto ??
                                        row?.user?.profile?.picture
                                      }`
                                    : null
                                }
                                name={`${row?.firstName} ${row?.lastName}`}
                                size="md"
                                mr={3}
                                alignSelf="start"
                              />
                              <Box>
                                <Flex wrap="wrap">
                                  <Text fontWeight="500" mr={2}>
                                    {row?.firstName} {row?.lastName}
                                  </Text>
                                  <Box
                                    w="16px"
                                    h="16px"
                                    position="relative"
                                    mr={2}
                                    mt="2px"
                                  >
                                    <FlagImage
                                      mr={2}
                                      language={row?.user.profile?.locale}
                                    />
                                  </Box>
                                </Flex>
                                <Text color="text.500" fontSize="xs">
                                  {row.editorialOffice?.officeRequest?.type ===
                                    "request" &&
                                  row.editorialOffice?.officeRequest?.status ===
                                    UserInvitationStatuses.REJECTED ? (
                                    <Flex alignItems="center">
                                      <VStack spacing={0} align="left">
                                        <Text>
                                          {t("events.freelancer")}
                                          <EditorialOfficeStatusIcon
                                            editorialOffice={
                                              row?.editorialOffice
                                            }
                                          />
                                          <StatusIcon
                                            status="editorialOffice.accreditationInformation.officeRejectedInvitation"
                                            icon={UserX}
                                            ml={0}
                                            color="red.500"
                                            _hover={{ color: "red.800" }}
                                          />
                                        </Text>
                                        <EditorialOfficeName
                                          editorialOffice={row?.editorialOffice}
                                        />
                                      </VStack>
                                    </Flex>
                                  ) : row.editorialOffice?.officeRequest
                                      ?.type === "request" &&
                                    row.editorialOffice?.officeRequest
                                      ?.status ===
                                      UserInvitationStatuses.WAITING ? (
                                    <Flex alignItems="center">
                                      <VStack spacing={0} align="left">
                                        <Text>
                                          {t("events.freelancer")}
                                          <EditorialOfficeStatusIcon
                                            editorialOffice={
                                              row?.editorialOffice
                                            }
                                          />
                                          <StatusIcon
                                            status="editorialOffice.accreditationInformation.officeHasGotInvitation"
                                            icon={UserPlus}
                                            ml={0}
                                            color="green.500"
                                            _hover={{ color: "green.800" }}
                                          />
                                        </Text>
                                        <EditorialOfficeName
                                          editorialOffice={row?.editorialOffice}
                                        />
                                      </VStack>
                                    </Flex>
                                  ) : (
                                    <>
                                      <EditorialOfficeName
                                        editorialOffice={row?.editorialOffice}
                                      >
                                        {row?.editorialOffice?.name
                                          ? row?.editorialOffice?.name
                                          : t("events.freelancer")}
                                      </EditorialOfficeName>
                                      <EditorialOfficeStatusIcon
                                        editorialOffice={row?.editorialOffice}
                                      />
                                    </>
                                  )}
                                </Text>
                              </Box>
                            </Flex>
                          </Flex>
                          <Flex p="1.25rem" justifyContent="space-between">
                            <AccreditationStatus
                              accreditationId={row.id}
                              eventId={eventId}
                              status={row.status}
                              openAccreditationPreview
                            />
                            <Flex
                              justifyContent="flex-end"
                              ml={1}
                              fontSize="20px"
                            >
                              <Tooltip
                                hasArrow
                                label={t("preview")}
                                placement="top"
                              >
                                <StyledLink
                                  lineHeight="1"
                                  p="1"
                                  onClick={() => openAccreditationModal(row.id)}
                                >
                                  <Icon as={Eye} />
                                </StyledLink>
                              </Tooltip>
                            </Flex>
                          </Flex>
                        </BoxCard>
                      );
                    })}
                  </SimpleGrid>
                </TabPanel>
                <TabPanel>
                  <Box d={{ base: "none", md: "block" }}>
                    <Table columns={columns} data={otherApplications || []} />
                  </Box>
                  <SimpleGrid
                    columns={1}
                    spacing={3}
                    d={{ base: "grid", md: "none" }}
                  >
                    {otherApplications?.map((row) => {
                      const accreditationPhoto = getAccreditationPhoto(
                        row?.fields
                      );

                      return (
                        <BoxCard p={0} key={row?.id}>
                          <Flex
                            align="center"
                            justify="space-between"
                            p="1.25rem"
                            borderBottomColor="light.50"
                            borderBottomWidth="1px"
                          >
                            <Flex align="center">
                              <Avatar
                                src={
                                  row?.user?.profile?.picture ||
                                  accreditationPhoto
                                    ? `/uploads/${
                                        accreditationPhoto ??
                                        row?.user?.profile?.picture
                                      }`
                                    : null
                                }
                                name={`${row?.firstName} ${row?.lastName}`}
                                size="md"
                                mr={3}
                                alignSelf="start"
                              />
                              <Box>
                                <Flex wrap="wrap">
                                  <Text fontWeight="500" mr={2}>
                                    {row?.firstName} {row?.lastName}
                                  </Text>
                                  <Box
                                    w="16px"
                                    h="16px"
                                    position="relative"
                                    mr={2}
                                    mt="2px"
                                  >
                                    <FlagImage
                                      mr={2}
                                      language={row?.user.profile?.locale}
                                    />
                                  </Box>
                                </Flex>
                                <Text color="text.500" fontSize="xs">
                                  {row.editorialOffice?.officeRequest?.type ===
                                    "request" &&
                                  row.editorialOffice?.officeRequest?.status ===
                                    UserInvitationStatuses.REJECTED ? (
                                    <Flex alignItems="center">
                                      <VStack spacing={0} align="left">
                                        <Text>
                                          {t("events.freelancer")}
                                          <EditorialOfficeStatusIcon
                                            editorialOffice={
                                              row?.editorialOffice
                                            }
                                          />
                                          <StatusIcon
                                            status="editorialOffice.accreditationInformation.officeRejectedInvitation"
                                            icon={UserX}
                                            ml={0}
                                            color="red.500"
                                            _hover={{ color: "red.800" }}
                                          />
                                        </Text>
                                        <EditorialOfficeName
                                          editorialOffice={row?.editorialOffice}
                                        />
                                      </VStack>
                                    </Flex>
                                  ) : row.editorialOffice?.officeRequest
                                      ?.type === "request" &&
                                    row.editorialOffice?.officeRequest
                                      ?.status ===
                                      UserInvitationStatuses.WAITING ? (
                                    <Flex alignItems="center">
                                      <VStack spacing={0} align="left">
                                        <Text>
                                          {t("events.freelancer")}
                                          <EditorialOfficeStatusIcon
                                            editorialOffice={
                                              row?.editorialOffice
                                            }
                                          />
                                          <StatusIcon
                                            status="editorialOffice.accreditationInformation.officeHasGotInvitation"
                                            icon={UserPlus}
                                            ml={0}
                                            color="green.500"
                                            _hover={{ color: "green.800" }}
                                          />
                                        </Text>
                                        <EditorialOfficeName
                                          editorialOffice={row?.editorialOffice}
                                        />
                                      </VStack>
                                    </Flex>
                                  ) : (
                                    <>
                                      <EditorialOfficeName
                                        editorialOffice={row?.editorialOffice}
                                      >
                                        {row?.editorialOffice?.name
                                          ? row?.editorialOffice?.name
                                          : t("events.freelancer")}
                                      </EditorialOfficeName>
                                      <EditorialOfficeStatusIcon
                                        editorialOffice={row?.editorialOffice}
                                      />
                                    </>
                                  )}
                                </Text>
                              </Box>
                            </Flex>
                          </Flex>
                          <Flex p="1.25rem" justifyContent="space-between">
                            <AccreditationStatus
                              accreditationId={row.id}
                              eventId={eventId}
                              status={row.status}
                              openAccreditationPreview
                            />
                            <Flex
                              justifyContent="flex-end"
                              ml={1}
                              fontSize="20px"
                            >
                              <Tooltip
                                hasArrow
                                label={t("preview")}
                                placement="top"
                              >
                                <StyledLink
                                  lineHeight="1"
                                  p="1"
                                  onClick={() => openAccreditationModal(row.id)}
                                >
                                  <Icon as={Eye} />
                                </StyledLink>
                              </Tooltip>
                            </Flex>
                          </Flex>
                        </BoxCard>
                      );
                    })}
                  </SimpleGrid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </BoxCard>
        )}

        {accreditationModal.isOpen && (
          <AccreditationModal
            id={selectedId}
            eventId={eventId}
            isOpen={accreditationModal.isOpen}
            onClose={closeAccreditationModal}
          />
        )}

        {/* {!list && (
          <BoxCard
            pb="0"
            w="400px"
            position="absolute"
            top="0"
            right="0"
            //          right="-54px"
            minHeight="100%"
            transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
            transitionDuration=".3s"
            transitionProperty="transform,right"
            //          transform="translateX(100%)"
          >
            <Box position="sticky" top="1.25rem">
              <Flex align="center" flex="1 1 0%">
                <Box position="relative">
                  <Avatar name="Imię Nazwisko" size="xl" />
                  <Box position="absolute" bottom="5px" right="5px">
                    <Menu>
                      <MenuButton role="group">
                        <IconButton
                          as="a"
                          aria-label={t("upload")}
                          size="xs"
                          icon={<Plus />}
                        />
                      </MenuButton>
                      <MenuList>
                        <MenuItem>{t("add")}</MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </Box>
                <Box ml="1.25rem" flex="1">
                  <Heading size="lg" mb={1}>
                    Imię Nazwisko
                  </Heading>
                  <Flex align="center" mt={3}>
                    <Text>Nazwa redakcji</Text>
                  </Flex>
                </Box>
                <Box alignSelf="flex-start">
                  <Image
                    dimensions={[40, 40]}
                    layout="fill"
                    objectFit="contain"
                    src="/flags/Poland.png"
                    alt="Language icon"
                  />
                </Box>
              </Flex>
              <Flex
                justify="space-between"
                mt={3}
                borderBottomColor="light.50"
                borderBottomWidth="1px"
                py={2}
              >
                <Text fontWeight="bold">{t("events.applicationDate")}</Text>
                <Text>01.01.2021</Text>
              </Flex>
              <Flex justify="space-between" py={2}>
                <Text fontWeight="bold">{t("events.accreditationNr")}</Text>
                <Text>123</Text>
              </Flex>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <SimpleGrid columns={1} spacingX={3} spacingY={3} mt={2}>
                  <FormControl isInvalid={!!errors.firstName} isRequired>
                    <Box>
                      <FormLabel marginBottom="0" htmlFor="firstName">
                        {t("users.firstName")}
                      </FormLabel>
                      <Input
                        autoFocus
                        //                      defaultValue={session?.user.firstName}
                        {...register("firstName", { required: true })}
                      />
                      {errors.firstName && (
                        <FormErrorMessage>
                          {t("users.fieldRequired")}
                        </FormErrorMessage>
                      )}
                    </Box>
                  </FormControl>
                  <FormControl isInvalid={!!errors.lastName} isRequired>
                    <Box>
                      <FormLabel marginBottom="0" htmlFor="lastName">
                        {t("users.lastName")}
                      </FormLabel>
                      <Input
                        //                      defaultValue={session?.user.lastName}
                        {...register("lastName", { required: true })}
                      />
                      {errors.lastName && (
                        <FormErrorMessage>
                          {t("users.fieldRequired")}
                        </FormErrorMessage>
                      )}
                    </Box>
                  </FormControl>
                  <FormControl isInvalid={!!errors.email} isRequired>
                    <Box>
                      <FormLabel marginBottom="0" htmlFor="email">
                        {t("users.email")}
                      </FormLabel>
                      <Input
                        type="email"
                        //                      defaultValue={session?.user.email}
                        {...register("email", {
                          required: true,
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: t("users.emailValidationError"),
                          },
                        })}
                      />
                      {errors.email && (
                        <FormErrorMessage>
                          {errors.email.message}
                        </FormErrorMessage>
                      )}
                    </Box>
                  </FormControl>
                  <FormControl>
                    <Box>
                      <FormLabel marginBottom="0" htmlFor="phone">
                        {t("users.phone")}
                      </FormLabel>
                      <Input
                        type="number"
                        //                      defaultValue={session?.user.phone}
                        {...register("phone")}
                      />
                    </Box>
                  </FormControl>
                  <FormControl>
                    <Box>
                      <FormLabel marginBottom="0" htmlFor="country">
                        {t("users.country")}
                      </FormLabel>
                    </Box>
                  </FormControl>
                </SimpleGrid>
              </form>
              <Flex
                justify="center"
                pt={2}
                bg="white"
                position="sticky"
                pb="1.25rem"
                bottom="0"
              >
                <Button mr={2}>{t("save")}</Button>
                <Button variant="green">{t("events.accredit")}</Button>
              </Flex>
            </Box>
          </BoxCard>
        )}{" "}
        //TODO uncomment when it will be necessary to accredit people from outside the system */}
      </Box>
    </>
  );
};

type StatusTypeAmountProps = {
  type: AccreditationTypes;
  amount: number;
  applications?: any[];
  applicationId?: number;
};

export const StatusTypeAmountBox = ({
  type,
  amount,
  applications,
  applicationId,
}: StatusTypeAmountProps): React.ReactElement => {
  const { t } = useTranslation("common");
  const [session] = useSession();
  const filteredApplications = useMemo(() => {
    return applications?.filter(
      (application) => application?.application?.id != applicationId
    );
  }, [applications, applicationId]);

  return (
    <>
      {filteredApplications?.length > 0 ? (
        <Popover trigger="hover" isLazy placement="top-start">
          <PopoverTrigger>
            <Box
              bgColor={getAccreditationStatusColor(type)}
              width={30}
              textAlign="center"
              fontWeight={"bold"}
              boxShadow="0 3px 20px rgba(0,0,0,0.04)"
              borderRadius=".375rem"
              color={"white"}
            >
              {amount}
            </Box>
          </PopoverTrigger>
          <Portal></Portal>
          <PopoverContent maxHeight="500px" maxWidth="400px" overflow="auto">
            <PopoverArrow />
            <PopoverHeader>
              <Flex flex="1" alignItems="center">
                {t(`events.accreditationTypesAmount.${type}`)}:
                <Text ml={3} fontWeight="500">
                  {amount}
                </Text>
              </Flex>
            </PopoverHeader>

            <PopoverBody>
              {filteredApplications?.map((application) => (
                <Flex align="center" mb={5} key={application?.application?.id}>
                  <Flex flex="1" direction="column">
                    <Box flex={1}>
                      <Heading size="lg" mb={0}>
                        <Flex align={"center"}>
                          {application?.application?.firstName}{" "}
                          {application?.application?.lastName}
                          <FlagImage
                            ml={2}
                            language={
                              application?.application?.user?.profile?.locale
                            }
                          />
                        </Flex>
                      </Heading>
                    </Box>

                    <Flex mt={3}>
                      <Icon as={Mail} w="15px" h="15px" mr={2} />
                      <Link
                        href={`mailto:${application?.application?.email}`}
                        isTruncated
                        maxW="calc(100% - 23px)"
                        color={"black"}
                        target="_blank"
                      >
                        {application?.application?.email}
                      </Link>
                    </Flex>
                    <VStack
                      spacing={3}
                      alignItems="flex-start"
                      mt={{ base: 3, md: 0 }}
                    >
                      <Stack
                        direction="row"
                        flexWrap={{ base: "wrap", md: "nowrap" }}
                        spacing={{ base: 0, md: 2 }}
                      >
                        <SocialMediaIcon
                          type={SocialMedia.FACEBOOK}
                          name={
                            application?.application?.facebook ||
                            application?.application?.user?.profile?.facebook
                          }
                        />
                        <SocialMediaIcon
                          type={SocialMedia.TWITTER}
                          name={
                            application?.application?.twitter ||
                            application?.application?.user?.profile?.twitter
                          }
                        />
                        <SocialMediaIcon
                          type={SocialMedia.INSTAGRAM}
                          name={
                            application?.application?.instagram ||
                            application?.application?.user?.profile?.instagram
                          }
                        />
                        <SocialMediaIcon
                          type={SocialMedia.YOUTUBE}
                          name={
                            application?.application?.youtube ||
                            application?.application?.user?.profile?.youtube
                          }
                        />
                        <SocialMediaIcon
                          type={SocialMedia.TWITCH}
                          name={
                            application?.application?.twitch ||
                            application?.application?.user?.profile?.twitch
                          }
                        />
                        <SocialMediaIcon
                          type={SocialMedia.LINKEDIN}
                          name={
                            application?.application?.linkedin ||
                            application?.application?.user?.profile?.linkedin
                          }
                        />
                        <SocialMediaIcon
                          type={SocialMedia.TIKTOK}
                          name={
                            application?.application?.tiktok ||
                            application?.application?.user?.profile?.tiktok
                          }
                        />
                        <SocialMediaIcon
                          type={SocialMedia.OTHER}
                          name={
                            application?.application?.otherSocial ||
                            application?.application?.user?.profile?.otherSocial
                          }
                        />
                      </Stack>
                    </VStack>
                    {application?.application?.types?.map((type, index) => (
                      <Flex gap={2} key={index}>
                        <Text>{`${type?.location?.name} - `}</Text>
                        <Text mr={2} fontWeight="bold" textAlign="center">
                          <AccreditationTypeName
                            type={type}
                            profile={session?.user?.profile}
                          />
                        </Text>
                      </Flex>
                    ))}
                    <Box mb={3}>
                      {application?.application?.fields?.map((field) => {
                        if (field.accreditationField) {
                          if (
                            field.accreditationField.type ===
                            AccreditationTypeFields.TEXT
                          ) {
                            if (field?.accreditationField?.default) {
                              if (
                                field.accreditationField.name.en === "address"
                              )
                                return (
                                  <Flex
                                    justify="space-between"
                                    py={2}
                                    borderBottomColor="light.50"
                                    borderBottomWidth="1px"
                                  >
                                    <Text fontWeight="bold" pr={4}>
                                      {t("address")}
                                    </Text>
                                    <Text>{`${field?.value?.postalCode || ""} ${
                                      field?.value?.city || ""
                                    }${
                                      field?.value?.city ||
                                      field?.value?.postalCode
                                        ? ", "
                                        : ""
                                    }${field?.value?.address || ""}${
                                      field?.value?.address ? ", " : ""
                                    }${field?.value?.country || ""}`}</Text>
                                  </Flex>
                                );
                              else if (
                                field.accreditationField.name.en ===
                                "publications"
                              )
                                return (
                                  <Flex
                                    py={2}
                                    borderBottomColor="light.50"
                                    borderBottomWidth="1px"
                                    flexDirection={"column"}
                                    maxH={200}
                                    overflowY="auto"
                                  >
                                    <Text
                                      fontWeight="bold"
                                      mb={2}
                                      py={2}
                                      borderBottomColor="light.50"
                                      borderBottomWidth="1px"
                                    >
                                      {t(
                                        `events.${field.accreditationField?.name.en}`
                                      )}
                                    </Text>
                                    <ParsedHTML html={field.value} />
                                  </Flex>
                                );
                              else
                                return (
                                  <Flex
                                    justify="space-between"
                                    py={2}
                                    borderBottomColor="light.50"
                                    borderBottomWidth="1px"
                                  >
                                    <Text fontWeight="bold" pr={4}>
                                      {t(
                                        `events.${field.accreditationField?.name.en}`
                                      )}
                                    </Text>
                                    <Text>{field.value}</Text>
                                  </Flex>
                                );
                            } else
                              return (
                                <Flex
                                  justify="space-between"
                                  py={2}
                                  borderBottomColor="light.50"
                                  borderBottomWidth="1px"
                                >
                                  <Text fontWeight="bold" pr={4}>
                                    {field.accreditationField?.name[
                                      session?.user?.profile?.locale
                                    ] ??
                                      field.accreditationField?.name.en ??
                                      field.accreditationField?.name[
                                        Object.keys(
                                          field.accreditationField?.name
                                        )[0]
                                      ]}
                                  </Text>
                                  <Text>{field.value}</Text>
                                </Flex>
                              );
                          }
                          if (
                            field.accreditationField.type ===
                            AccreditationTypeFields.CHECKBOX
                          )
                            return (
                              <Flex
                                justify="space-between"
                                py={2}
                                borderBottomColor="light.50"
                                borderBottomWidth="1px"
                              >
                                <Text fontWeight="bold" pr={4}>
                                  <AccreditationTypeName
                                    type={field?.accreditationField}
                                    profile={session?.user?.profile}
                                  />
                                  :
                                </Text>
                                <Switch
                                  ml={2}
                                  isDisabled
                                  isChecked={field.value}
                                />
                              </Flex>
                            );

                          if (
                            field.accreditationField.type ===
                              AccreditationTypeFields.FILE &&
                            field.accreditationField?.name.en !== "photo"
                          )
                            return (
                              <>
                                <Flex
                                  justify="space-between"
                                  py={2}
                                  borderBottomColor="light.50"
                                  borderBottomWidth="1px"
                                  align={"center"}
                                >
                                  <Text fontWeight="bold" pr={4}>
                                    <AccreditationTypeName
                                      type={field?.accreditationField}
                                      profile={session?.user?.profile}
                                    />
                                    :
                                  </Text>
                                  <Flex align={"center"}>
                                    <Text ml={2}>
                                      {field?.value?.originalname}
                                    </Text>
                                    <Link
                                      ml={2}
                                      target="_blank"
                                      href={`${publicRuntimeConfig.basePath}/uploads/${field?.value?.filename}`}
                                      rel="noopener noreferrer"
                                    >
                                      <Flex
                                        maxW="100%"
                                        mb={1}
                                        mr={2}
                                        alignItems="center"
                                      >
                                        <Icon as={File} mr={1} />
                                        <Text>{t("preview")}</Text>
                                      </Flex>
                                    </Link>
                                    {field?.value?.mimetype?.match(
                                      IMAGE_MIMETYPES_REGEX
                                    ) && (
                                      <Box
                                        w={100}
                                        h={100}
                                        position="relative"
                                        borderRadius="50%"
                                        overflow="hidden"
                                      >
                                        <Image
                                          src={`${publicRuntimeConfig.basePath}/uploads/${field?.value?.filename}`}
                                          alt={field?.value?.filename}
                                          objectFit="cover"
                                          unoptimized
                                          quality={90}
                                        />
                                      </Box>
                                    )}
                                  </Flex>
                                </Flex>
                              </>
                            );
                        }
                      })}
                    </Box>
                  </Flex>
                </Flex>
              ))}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        <Tooltip
          hasArrow
          label={t(`events.accreditationTypesAmount.${type}`)}
          placement="top"
        >
          <div>
            <Jump spy={amount}>
              <Box
                bgColor={getAccreditationStatusColor(type)}
                width={30}
                textAlign="center"
                fontWeight={"bold"}
                boxShadow="0 3px 20px rgba(0,0,0,0.04)"
                borderRadius=".375rem"
                color={"white"}
              >
                {amount}
              </Box>
            </Jump>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default Applications;
