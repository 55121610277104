import React from "react";
import { Text } from "@chakra-ui/react";
import { EditorialOffice } from "@/types/office";
import { UserInvitationStatuses } from "@/types/user";

const EditorialOfficeName = ({
  editorialOffice,
  children,
}: {
  editorialOffice: EditorialOffice;
  children?: JSX.Element;
}): JSX.Element => {
  return (
    <Text
      color={
        editorialOffice?.name
          ? editorialOffice?.officeRequest?.type === "request" &&
            editorialOffice?.officeRequest?.status ===
              UserInvitationStatuses.REJECTED
            ? "red.500"
            : !editorialOffice?.activated
            ? "gray.300"
            : undefined
          : undefined
      }
    >
      {children ?? editorialOffice.name}
    </Text>
  );
};

export default EditorialOfficeName;
