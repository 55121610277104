import React from "react";
import { useTranslation } from "react-i18next";
import { IconProps } from "react-feather";
import {
  ComponentWithAs,
  LinkProps,
  Tooltip,
  Link as StyledLink,
  Icon,
} from "@chakra-ui/react";

const StatusIcon = ({
  status,
  icon,
  ...props
}: {
  status: string;
  icon: ComponentWithAs<"svg", IconProps>;
} & LinkProps): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <Tooltip hasArrow label={t(status)} placement="top">
      <StyledLink
        lineHeight="1"
        display="inline-flex"
        alignItems="center"
        p="1"
        fontSize=".875rem"
        ml={1}
        {...props}
      >
        <Icon as={icon} />
      </StyledLink>
    </Tooltip>
  );
};

export default StatusIcon;
