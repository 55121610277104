import React from "react";
import { Icon, Flex, Text, BoxProps } from "@chakra-ui/react";
import {
  CheckCircle,
  XCircle,
  PauseCircle,
  PieChart,
  AlertCircle,
} from "react-feather";
import { useTranslation } from "next-i18next";
import { AccreditationTypes } from "@/types/accreditation";
import { getAccreditationStatusColor } from "@/utils/accreditation";

type Props = {
  status: AccreditationTypes;
  isDisabled?: boolean;
};

const AccreditationStatusButton = ({
  status,
  isDisabled = false,
  ...props
}: Props & BoxProps): React.ReactElement => {
  const { t } = useTranslation("common");
  let icon = null;

  if (status === AccreditationTypes.PENDING) icon = PauseCircle;
  if (status === AccreditationTypes.GRANTED) icon = CheckCircle;
  if (status === AccreditationTypes.INCOMPLETE) icon = PieChart;
  if (status === AccreditationTypes.CANCELED) icon = AlertCircle;
  if (status === AccreditationTypes.REJECTED) icon = XCircle;

  return (
    <Flex
      as="button"
      color={getAccreditationStatusColor(status)}
      borderColor={getAccreditationStatusColor(status)}
      align="center"
      opacity={isDisabled ? "0.5" : undefined}
      {...props}
    >
      <Icon as={icon} w={4} h={4} mr={2} />
      <Text>{t(`events.${status}`)}</Text>
    </Flex>
  );
};

export default AccreditationStatusButton;
