import {
  Box,
  Center,
  Link as ChakraLink,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { format } from "date-fns";
import { useSession } from "next-auth/client";
import { useTranslation } from "next-i18next";
import getConfig from "next/config";
import React, { useMemo } from "react";
import { CheckCircle, File, UserPlus, UserX } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import useSWR, { mutate } from "swr";

import Avatar from "@/components/common/Avatar";
import EditorialOfficeName from "@/components/common/EditorialOffice/Name";
import EditorialOfficeStatusIcon from "@/components/common/EditorialOffice/StatusIcon";
import FlagImage from "@/components/common/FlagImage";
import ParsedHTML from "@/components/common/ParsedHTML";
import SelectAdvanced from "@/components/common/SelectAdvanced";
import SocialMediaIcon from "@/components/common/SocialMediaIcon";
import StatusIcon from "@/components/common/StatusIcon";
import { useToastPromise } from "@/hooks/useToast";
import {
  AccreditationTypes,
  ApplicationListStatuses,
} from "@/types/accreditation";
import { AccreditationTypeFields } from "@/types/event";
import { SocialMedia } from "@/types/socialMedia";
import { UserInvitationStatuses } from "@/types/user";
import { getAccreditationPhoto } from "@/utils/accreditation";
import { colorSchemes } from "@/utils/colorSchemes";
import { prependURL } from "@/utils/helpers";
import { Mail } from "react-feather";
import AccreditationStatus from "./AccreditationStatus";
import { StatusTypeAmountBox } from "./Applications";

type Props = {
  isOpen: boolean;
  id: number;
  eventId: number;
  onClose: () => void;
};

const IMAGE_MIMETYPES_REGEX = /image\/png|image\/jpeg|image\/gif/;

const AccreditationModal = ({
  id,
  eventId,
  isOpen,
  onClose,
}: Props): React.ReactElement => {
  const { t } = useTranslation("common");
  const [session] = useSession();
  const { publicRuntimeConfig } = getConfig();

  const { data } = useSWR(id ? `/accreditation/${id}` : null);
  const { data: applicationData } = useSWR(
    eventId ? `/accreditation/event/${eventId}` : null
  );

  const { data: applicationsData } = useSWR(
    eventId
      ? `/accreditation/list/${eventId}?editorialOfficeId=${data?.editorialOffice?.id}`
      : null
  );
  const toast = useToastPromise();
  const { control, setValue, handleSubmit } = useForm();

  const applicationsGroupedByLocationAndStatus = useMemo(() => {
    const typesWithStatus = applicationsData?.flatMap((application) =>
      application?.types?.map((type) => ({
        status: application?.status,
        location: type?.location?.id ? type?.location?.id : null,
        application: application,
      }))
    );

    const groupedByLocationAndStatus = {};

    typesWithStatus?.forEach(({ status, location, application }) => {
      if (!groupedByLocationAndStatus?.[location]) {
        groupedByLocationAndStatus[location] = {};
      }
      if (!groupedByLocationAndStatus?.[location]?.[status]) {
        groupedByLocationAndStatus[location][status] = [];
      }
      groupedByLocationAndStatus?.[location]?.[status]?.push({
        status: status,
        location: location,
        application: application,
      });
    });

    return groupedByLocationAndStatus;
  }, [applicationsData]);

  const onSubmit = (data) => {
    return toast.promise(
      axios
        .post(`/accreditation/${id}`, {
          types: data.locations,
          status: ApplicationListStatuses.GRANTED,
        })
        .then(() => {
          mutate(
            `/accreditation/list/${eventId}/${ApplicationListStatuses.PENDING}`
          );
          mutate(
            `/accreditation/list/${eventId}/${ApplicationListStatuses.GRANTED}`
          );
          mutate(
            `/accreditation/list/${eventId}/${ApplicationListStatuses.OTHER}`
          );
        }),
      {
        errorKeys: {
          AccreditationStatusChangeNotAllowed: t(
            "errorKeys.accreditationStatusChangeNotAllowed"
          ),
          AccreditationWrongType: t("errorKeys.accreditationWrongType"),
          AccreditationTypeLimitExceed: t(
            "errorKeys.accreditationTypeLimitExceed"
          ),
        },
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      preserveScrollBarGap
      size="2xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize="1rem"
          fontWeight="500"
          borderBottomColor="light.50"
          borderBottomWidth="1px"
        >
          {`${data?.event?.name} (${format(
            new Date(data?.event?.startDate ?? Date.now()),
            "dd/MM/yyyy"
          )} - ${format(
            new Date(data?.event?.endDate ?? Date.now()),
            "dd/MM/yyyy"
          )})`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow="visible">
          <Flex align="center" mb={3}>
            <Avatar
              src={`/uploads/${
                getAccreditationPhoto(data?.fields) ??
                data?.user?.profile?.picture
              }`}
              size="xl"
              name={`${data?.firstName} ${data?.lastName}`}
            />
            <Flex
              ml="1.25rem"
              flex="1"
              direction={{ base: "column", md: "row" }}
            >
              <Box flex={1} pr={{ base: 0, md: 4 }}>
                <Heading size="lg" mb={1}>
                  <Flex align={"center"}>
                    {data?.firstName} {data?.lastName}
                    <FlagImage ml={2} language={data?.user?.profile?.locale} />
                  </Flex>
                </Heading>
                <Flex align="center" mt={3}>
                  {data?.editorialOffice?.officeRequest?.type === "request" &&
                  data?.editorialOffice?.officeRequest?.status ===
                    UserInvitationStatuses.REJECTED ? (
                    <VStack spacing={0} align="left">
                      <Text>
                        {t("events.freelancer")}
                        <EditorialOfficeStatusIcon
                          editorialOffice={data?.editorialOffice}
                        />
                        <StatusIcon
                          status="editorialOffice.accreditationInformation.officeRejectedInvitation"
                          icon={UserX}
                          ml={0}
                          color="red.500"
                          _hover={{ color: "red.800" }}
                        />
                      </Text>
                      <EditorialOfficeName
                        editorialOffice={data?.editorialOffice}
                      />
                    </VStack>
                  ) : data?.editorialOffice?.officeRequest?.type ===
                      "request" &&
                    data?.editorialOffice?.officeRequest?.status ===
                      UserInvitationStatuses.WAITING ? (
                    <VStack spacing={0} align="left">
                      <Text>
                        {t("events.freelancer")}
                        <EditorialOfficeStatusIcon
                          editorialOffice={data?.editorialOffice}
                        />
                        <StatusIcon
                          status="editorialOffice.accreditationInformation.officeHasGotInvitation"
                          icon={UserPlus}
                          ml={0}
                          color="green.500"
                          _hover={{ color: "green.800" }}
                        />
                      </Text>
                      <EditorialOfficeName
                        editorialOffice={data?.editorialOffice}
                      />
                    </VStack>
                  ) : (
                    <>
                      <EditorialOfficeName
                        editorialOffice={data?.editorialOffice}
                      >
                        {data?.editorialOffice?.name
                          ? data?.editorialOffice?.name
                          : t("events.freelancer")}
                      </EditorialOfficeName>
                      <EditorialOfficeStatusIcon
                        editorialOffice={data?.editorialOffice}
                      />
                    </>
                  )}
                </Flex>
                <Flex align="center" mt={3}>
                  <Icon as={Mail} w="15px" h="15px" mr={2} />
                  <ChakraLink
                    href={`mailto:${data?.email}`}
                    isTruncated
                    maxW="calc(100% - 23px)"
                    color={"black"}
                    target="_blank"
                  >
                    {data?.email}
                  </ChakraLink>
                </Flex>
                <Flex direction="row" style={{ marginTop: 10 }}>
                  {(data?.user?.editorialOfficeUsers || [])
                    ?.filter(({ editorialOffice }) => !!editorialOffice)
                    ?.map(({ id, editorialOffice: office }) => {
                      const showAvatar = !(
                        office?.officeRequests?.find(
                          (request) => request?.email == data?.email
                        )?.status == UserInvitationStatuses.REJECTED
                      );
                      if (showAvatar)
                        return (
                          <Flex key={id}>
                            <Avatar
                              src={
                                office?.picture
                                  ? `/uploads/${office?.picture}`
                                  : null
                              }
                              name={office?.name}
                              tooltip={office?.name}
                              mr={2}
                            />
                          </Flex>
                        );
                      return <></>;
                    })}
                </Flex>
              </Box>
              <VStack
                spacing={3}
                alignItems="flex-start"
                mt={{ base: 3, md: 0 }}
              >
                <Stack
                  direction="row"
                  flexWrap={{ base: "wrap", md: "nowrap" }}
                  spacing={{ base: 0, md: 2 }}
                >
                  <SocialMediaIcon
                    type={SocialMedia.FACEBOOK}
                    name={data?.facebook || data?.user?.profile?.facebook}
                  />
                  <SocialMediaIcon
                    type={SocialMedia.TWITTER}
                    name={data?.twitter || data?.user?.profile?.twitter}
                  />
                  <SocialMediaIcon
                    type={SocialMedia.INSTAGRAM}
                    name={data?.instagram || data?.user?.profile?.instagram}
                  />
                  <SocialMediaIcon
                    type={SocialMedia.YOUTUBE}
                    name={data?.youtube || data?.user?.profile?.youtube}
                  />
                  <SocialMediaIcon
                    type={SocialMedia.TWITCH}
                    name={data?.twitch || data?.user?.profile?.twitch}
                  />
                  <SocialMediaIcon
                    type={SocialMedia.LINKEDIN}
                    name={data?.linkedin || data?.user?.profile?.linkedin}
                  />
                  <SocialMediaIcon
                    type={SocialMedia.TIKTOK}
                    name={data?.tiktok || data?.user?.profile?.tiktok}
                  />
                  <SocialMediaIcon
                    type={SocialMedia.OTHER}
                    name={data?.otherSocial || data?.user?.profile?.otherSocial}
                  />
                </Stack>
                <Stack
                  direction="row"
                  flexWrap={{ base: "wrap", md: "nowrap" }}
                  spacing={{ base: 0, md: 2 }}
                >
                  {data?.user?.profile?.editorialOffice?.name && (
                    <Tooltip label={data?.user?.profile?.editorialOffice?.name}>
                      {data?.user?.profile?.editorialOffice?.url ? (
                        <Link
                          href={prependURL(
                            data?.user?.profile?.editorialOffice?.url
                          )}
                          isExternal
                        >
                          <Avatar
                            src={`/uploads/${data?.user?.profile?.editorialOffice?.picture}`}
                            name={data?.user?.profile?.editorialOffice?.name}
                          />
                        </Link>
                      ) : (
                        <Avatar
                          src={`/uploads/${data?.user?.profile?.editorialOffice?.picture}`}
                          name={data?.user?.profile?.editorialOffice?.name}
                        />
                      )}
                    </Tooltip>
                  )}
                </Stack>
              </VStack>
            </Flex>
          </Flex>
          <Box mb={3}>
            {data?.fields?.map((field) => {
              if (field.accreditationField) {
                if (
                  field.accreditationField.type === AccreditationTypeFields.TEXT
                ) {
                  if (field?.accreditationField?.default) {
                    if (field.accreditationField.name.en === "address")
                      return (
                        <Flex
                          justify="space-between"
                          py={2}
                          borderBottomColor="light.50"
                          borderBottomWidth="1px"
                        >
                          <Text fontWeight="bold" pr={4}>
                            {t("address")}
                          </Text>
                          <Text>{`${field?.value?.postalCode || ""} ${
                            field?.value?.city || ""
                          }${
                            field?.value?.city || field?.value?.postalCode
                              ? ", "
                              : ""
                          }${field?.value?.address || ""}${
                            field?.value?.address ? ", " : ""
                          }${field?.value?.country || ""}`}</Text>
                        </Flex>
                      );
                    else if (
                      field.accreditationField.name.en === "publications"
                    )
                      return (
                        <Flex
                          py={2}
                          borderBottomColor="light.50"
                          borderBottomWidth="1px"
                          flexDirection={"column"}
                          maxH={200}
                          overflowY="auto"
                        >
                          <Text
                            fontWeight="bold"
                            mb={2}
                            py={2}
                            borderBottomColor="light.50"
                            borderBottomWidth="1px"
                          >
                            {t(`events.${field.accreditationField?.name.en}`)}
                          </Text>
                          <ParsedHTML html={field.value} />
                        </Flex>
                      );
                    else
                      return (
                        <Flex
                          justify="space-between"
                          py={2}
                          borderBottomColor="light.50"
                          borderBottomWidth="1px"
                        >
                          <Text fontWeight="bold" pr={4}>
                            {t(`events.${field.accreditationField?.name.en}`)}
                          </Text>
                          <Text>{field.value}</Text>
                        </Flex>
                      );
                  } else
                    return (
                      <Flex
                        justify="space-between"
                        py={2}
                        borderBottomColor="light.50"
                        borderBottomWidth="1px"
                      >
                        <Text fontWeight="bold" pr={4}>
                          {field.accreditationField?.name[
                            session?.user?.profile?.locale
                          ] ??
                            field.accreditationField?.name.en ??
                            field.accreditationField?.name[
                              Object.keys(field.accreditationField?.name)[0]
                            ]}
                        </Text>
                        <Text>{field.value}</Text>
                      </Flex>
                    );
                }

                let label;

                if (field?.accreditationField?.default)
                  label = t(`events.${field.accreditationField?.name.en}`);
                else
                  label =
                    field.accreditationField?.name[
                      session?.user?.profile?.locale
                    ] ??
                    field.accreditationField?.name.en ??
                    field.accreditationField?.name[
                      Object.keys(field.accreditationField?.name)[0]
                    ];

                if (
                  field.accreditationField.type ===
                  AccreditationTypeFields.CHECKBOX
                )
                  return (
                    <Flex
                      justify="space-between"
                      py={2}
                      borderBottomColor="light.50"
                      borderBottomWidth="1px"
                    >
                      <Text fontWeight="bold" pr={4}>
                        {label}:
                      </Text>
                      <Switch ml={2} isDisabled isChecked={field.value} />
                    </Flex>
                  );

                if (
                  field.accreditationField.type ===
                    AccreditationTypeFields.FILE &&
                  field.accreditationField?.name.en !== "photo"
                )
                  return (
                    <>
                      <Flex
                        justify="space-between"
                        py={2}
                        borderBottomColor="light.50"
                        borderBottomWidth="1px"
                        align={"center"}
                      >
                        <Text fontWeight="bold" pr={4}>
                          {label}:
                        </Text>
                        <Flex align={"center"}>
                          <Text ml={2}>{field?.value?.originalname}</Text>
                          <Link
                            ml={2}
                            target="_blank"
                            href={`${publicRuntimeConfig.basePath}/uploads/${field?.value?.filename}`}
                            rel="noopener noreferrer"
                          >
                            <Flex maxW="100%" mb={1} mr={2} alignItems="center">
                              <Icon as={File} mr={1} />
                              <Text>{t("preview")}</Text>
                            </Flex>
                          </Link>
                          {field?.value?.mimetype?.match(
                            IMAGE_MIMETYPES_REGEX
                          ) && (
                            <Box
                              w={100}
                              h={100}
                              position="relative"
                              borderRadius="50%"
                              overflow="hidden"
                            >
                              <Image
                                src={`${publicRuntimeConfig.basePath}/uploads/${field?.value?.filename}`}
                                alt={field?.value?.filename}
                                objectFit="cover"
                                unoptimized
                                quality={90}
                              />
                            </Box>
                          )}
                        </Flex>
                      </Flex>
                    </>
                  );
              }
            })}
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            {applicationData?.event?.accreditationLocations.map(
              (location, index) => {
                return (
                  <>
                    <Flex
                      key={location.id}
                      align="center"
                      justify="space-between"
                      mb={2}
                    >
                      <Heading size="md" pr={4}>
                        {location.location?.name || ""}
                      </Heading>
                      <Text fontSize={"xs"} color={"text.400"} mt={1}>
                        {location.location?.address || ""}
                      </Text>
                    </Flex>
                    <Flex mb={3} alignItems={"center"}>
                      <FormLabel pb={0} pr={3}>
                        {t("events.accreditationType")}:
                      </FormLabel>
                      {data?.types?.map((type) => {
                        if (
                          type.accreditationLocation.location.id ===
                          location.location.id
                        ) {
                          const choosedType = location.accreditationTypes?.map(
                            (type) => {
                              return data?.types?.filter(
                                (accreditationType) =>
                                  accreditationType.id === type.id
                              );
                            }
                          );

                          setValue(
                            `locations.${index}.id`,
                            choosedType.flat(1)[0]?.id || null
                          );

                          const accreditationTypeOptions = [
                            ...location?.accreditationTypes?.filter(
                              (type) => type.active || !type.default
                            ),
                          ].map((type) => {
                            let label;

                            if (type.limitReached) {
                              if (type.default) {
                                label = `${t(
                                  `events.accreditationTypes.${type?.name.en}`
                                )} - ${t(
                                  "events.accreditationTypeFullLimit"
                                )}}`;
                              } else {
                                label = `${
                                  type.name[session?.user?.profile?.locale] ??
                                  type.name.en ??
                                  type.name[Object.keys(type.name)[0]]
                                } - ${t("events.accreditationTypeFullLimit")}`;
                              }
                            } else {
                              if (type.default) {
                                label = `${t(
                                  `events.accreditationTypes.${type?.name.en}`
                                )}`;
                              } else {
                                label =
                                  type.name[session?.user?.profile?.locale] ??
                                  type.name.en ??
                                  type.name[Object.keys(type.name)[0]];
                              }
                            }

                            return {
                              label: label,
                              value: type.id,
                              disabled: type.limitReached,
                            };
                          });

                          return (
                            <Box width={200} mr={2}>
                              <Controller
                                control={control}
                                name={`locations.${index}.id`}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <SelectAdvanced
                                    menuPlacement="top"
                                    options={accreditationTypeOptions}
                                    isOptionDisabled={(option) =>
                                      option.disabled
                                    }
                                    ref={ref}
                                    onChange={(selectedValue) => {
                                      onChange(selectedValue?.value);
                                    }}
                                    value={accreditationTypeOptions?.find(
                                      (c) => value === c?.value
                                    )}
                                    isClearable={false}
                                  />
                                )}
                              />
                            </Box>
                          );
                        }
                      })}
                    </Flex>
                    <SimpleGrid columns={2} spacingY={2}>
                      {data?.fields?.map((field) => {
                        if (
                          field?.accreditationOption?.accreditationLocations[0]
                            ?.location?.id === location.location.id
                        ) {
                          return (
                            <Flex>
                              <Text fontWeight={"500"}>
                                {field.accreditationOption?.name[
                                  session?.user?.profile?.locale
                                ] ??
                                  field.accreditationOption?.name.en ??
                                  field.accreditationOption?.name[
                                    Object.keys(
                                      field.accreditationOption?.name
                                    )[0]
                                  ]}
                                :
                              </Text>
                              {field?.value ? (
                                <Text ml={2}>{field?.value}</Text>
                              ) : (
                                <Switch ml={2} isChecked isDisabled />
                              )}
                            </Flex>
                          );
                        }
                      })}
                    </SimpleGrid>
                    <Flex alignItems="center" mb={3}>
                      <Heading size="md" pr={4}>
                        {t("events.otherAccreditationsFromEditorial")}
                      </Heading>
                      {!!data?.editorialOffice?.name && (
                        <HStack>
                          <StatusTypeAmountBox
                            type={AccreditationTypes.PENDING}
                            amount={
                              applicationsGroupedByLocationAndStatus?.[
                                location?.location?.id
                              ]?.[AccreditationTypes.PENDING]?.length ?? 0
                            }
                            applications={
                              applicationsGroupedByLocationAndStatus?.[
                                location?.location?.id
                              ]?.[AccreditationTypes.PENDING]
                            }
                            applicationId={id}
                          />
                          <StatusTypeAmountBox
                            type={AccreditationTypes.GRANTED}
                            amount={
                              applicationsGroupedByLocationAndStatus?.[
                                location?.location?.id
                              ]?.[AccreditationTypes.GRANTED]?.length ?? 0
                            }
                            applications={
                              applicationsGroupedByLocationAndStatus?.[
                                location?.location?.id
                              ]?.[AccreditationTypes.GRANTED]
                            }
                            applicationId={id}
                          />
                          <StatusTypeAmountBox
                            type={AccreditationTypes.REJECTED}
                            amount={
                              +(
                                (+applicationsGroupedByLocationAndStatus?.[
                                  location?.location?.id
                                ]?.[AccreditationTypes.CANCELED]?.length > 0
                                  ? applicationsGroupedByLocationAndStatus?.[
                                      location?.location?.id
                                    ]?.[AccreditationTypes.CANCELED]?.length
                                  : 0) +
                                (+applicationsGroupedByLocationAndStatus?.[
                                  location?.location?.id
                                ]?.[AccreditationTypes.REJECTED]?.length > 0
                                  ? applicationsGroupedByLocationAndStatus?.[
                                      location?.location?.id
                                    ]?.[AccreditationTypes.REJECTED]?.length
                                  : 0)
                              ) ?? 0
                            }
                            applications={[
                              ...(applicationsGroupedByLocationAndStatus?.[
                                location?.location?.id
                              ]?.[AccreditationTypes.CANCELED] || []),
                              ...(applicationsGroupedByLocationAndStatus?.[
                                location?.location?.id
                              ]?.[AccreditationTypes.REJECTED] || []),
                            ]}
                            applicationId={id}
                          />
                          <StatusTypeAmountBox
                            type={AccreditationTypes.INCOMPLETE}
                            amount={
                              applicationsGroupedByLocationAndStatus?.[
                                location?.location?.id
                              ]?.[AccreditationTypes.INCOMPLETE]?.length ?? 0
                            }
                            applications={
                              applicationsGroupedByLocationAndStatus?.[
                                location?.location?.id
                              ]?.[AccreditationTypes.INCOMPLETE]
                            }
                            applicationId={id}
                          />
                        </HStack>
                      )}
                    </Flex>

                    <Divider mb={3} />
                  </>
                );
              }
            )}
          </form>
          <Center>
            {data?.status === ApplicationListStatuses.PENDING && (
              <IconButton
                aria-label="granted-button"
                icon={<CheckCircle />}
                mr={1}
                onClick={handleSubmit(onSubmit)}
                {...colorSchemes("green")}
                size="sm"
                w="100px"
              />
            )}
            <AccreditationStatus
              accreditationId={id}
              status={data?.status}
              eventId={eventId}
              grantedButton={false}
              parentModalOnClose={onClose}
            />
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AccreditationModal;
