import React from "react";
import { Box, Flex, Link as StyledLink, Icon } from "@chakra-ui/react";

import { Image } from "@/components/common/Image";
import { getSocialMediaURL } from "@/utils/socialMedia";
import { SocialMedia } from "@/types/socialMedia";
import { Globe } from "react-feather";

type Props = {
  type: SocialMedia;
  name: string;
};

const SocialMediaIcon = ({ type, name }: Props): React.ReactElement => {
  if (!name) return null;

  return (
    <StyledLink href={getSocialMediaURL(name, type)} isExternal>
      <Flex
        align="center"
        justify="center"
        w="32px"
        h="32px"
        borderRadius="32px"
        borderWidth="1px"
        borderColor="light.50"
        cursor="pointer"
        mr={{ base: 2, md: 0 }}
      >
        {type == "other" ? (
          <Icon as={Globe} />
        ) : (
          <Box w="16px" h="16px" position="relative">
            <Image src={`/${type}.png`} alt="" />
          </Box>
        )}
      </Flex>
    </StyledLink>
  );
};

export default SocialMediaIcon;
