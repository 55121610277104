import { Profile } from "@/types/user";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

const AccreditationTypeName = ({
  type,
  profile,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type: any;
  profile: Profile;
}): React.ReactElement => {
  const { t } = useTranslation("common");
  if (type?.default)
    return <Text>{t(`events.accreditationTypes.${type?.name?.en}`)}</Text>;
  return (
    <Text>
      {type?.name[profile?.locale] ||
        type?.name.en ||
        type?.name[Object?.keys(type?.name)?.[0]]}
    </Text>
  );
};

export default AccreditationTypeName;
