import { LanguageName, LanguageShort } from "./common";
import { PressOffice, Regulation } from "./office";

export enum EventType {
  EVENT = "event",
  CONFERENCE = "conference",
}

export type Event = {
  id: number;
  pressOffice: PressOffice;
  locations: Location[];
  name: string;
  type: EventType;
  startDate: Date;
  endDate: Date;
  timezone: string;
  description?: { [x in LanguageShort]: string };
  bgColor?: string;
  boxColor?: string;
  textColor?: string;
  mainColor?: string;
  picture?: string;
};

export type Location = {
  id: number;
  pressOffice: PressOffice;
  name: string;
  address?: string;
  lat?: number;
  lng?: number;
};

export enum AccreditationTypeFields {
  TEXT = "text",
  FILE = "file",
  CHECKBOX = "checkbox",
}

export type AccreditationFields = {
  id?: number;
  name: LanguageName;
  required: boolean;
  default: boolean;
  type: AccreditationTypeFields;
  identiId?: number;
};

export type AccreditationTypes = {
  id?: number;
  name: LanguageName;
  limit?: number;
  active: boolean;
  default: boolean;
  accessTypes: { id: number }[];
};

export type AccreditationOptions = {
  id?: number;
  name: LanguageName;
  description: LanguageName;
  required: boolean;
};

export type AccreditationAgreements = {
  id?: number;
  regulation: Regulation;
  required: { [x in LanguageShort]: boolean };
};

export type AccreditationLocations = {
  id?: number;
  startAccreditation: Date;
  endAccreditation: Date;
  additionalInformation: LanguageName;
  accreditationTypes: AccreditationTypes[];
  accreditationOptions: AccreditationOptions[];
  location?: Location;
};
