import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { useTranslation } from "next-i18next";
import React, { useEffect, useMemo, useState } from "react";
import { CheckCircle, ChevronDown, XCircle } from "react-feather";
import { useForm } from "react-hook-form";
import useSWR, { mutate } from "swr";

import AccreditationStatusButton from "@/components/Events/Applications/AccreditationStatusButton";
import FlagImage from "@/components/common/FlagImage";
import HelpTooltip from "@/components/common/HelpTooltip";
import SelectAdvanced from "@/components/common/SelectAdvanced";
import { useToastPromise } from "@/hooks/useToast";
import {
  AccreditationTypes,
  ApplicationListStatuses,
} from "@/types/accreditation";
import { colorSchemes } from "@/utils/colorSchemes";
import AccreditationModal from "./AccreditationModal";

type Props = {
  accreditationId: number;
  eventId: number;
  status: AccreditationTypes;
  openAccreditationPreview?: boolean;
  grantedButton?: boolean;
  inTable?: boolean;
  parentModalOnClose?: () => void | null;
};

const AccreditationStatus = ({
  accreditationId,
  eventId,
  status,
  openAccreditationPreview = false,
  grantedButton = true,
  inTable = false,
  parentModalOnClose = null,
}: Props): React.ReactElement => {
  const { t } = useTranslation("common");
  const toast = useToastPromise();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [modalSelectedStatus, setModalSelectedStatus] = useState(null);
  const [incompleteStatus, setIncompleteStatus] = useState(false);
  const accreditationModal = useDisclosure();
  const { data: accreditationData } = useSWR(
    accreditationId ? `/accreditation/${accreditationId}` : null
  );

  const closeParentModal = () => {
    if (parentModalOnClose) parentModalOnClose();
  };

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm();

  const onChangeStatus = (accreditationType: AccreditationTypes) => {
    if (
      accreditationType === AccreditationTypes.CANCELED ||
      accreditationType === AccreditationTypes.REJECTED ||
      accreditationType === AccreditationTypes.PENDING
    )
      return onOpenMessageModal(accreditationType);

    if (accreditationType === AccreditationTypes.INCOMPLETE) {
      setIncompleteStatus(true);
      return onOpenMessageModal(accreditationType);
    }
    closeParentModal();
    return updateStatus(accreditationType);
  };

  const onSubmit = (data) => {
    updateStatus(modalSelectedStatus, data?.message);
    onClose();
    closeParentModal();
  };

  const onOpenMessageModal = (status: AccreditationTypes) => {
    if (status !== AccreditationTypes.INCOMPLETE) setIncompleteStatus(false);
    setModalSelectedStatus(status);
    onOpen();
  };

  useEffect(() => {
    if (selectedStatus !== status) onChangeStatus(selectedStatus?.value);
  }, [selectedStatus]);

  const updateStatus = (
    accreditationType: AccreditationTypes,
    message?: string
  ) => {
    const data = { status: accreditationType };

    if (message) data["message"] = message;

    return toast.promise(
      axios.post(`/accreditation/${accreditationId}`, data).then(() => {
        mutate(
          `/accreditation/list/${eventId}/${ApplicationListStatuses.PENDING}`
        );
        mutate(
          `/accreditation/list/${eventId}/${ApplicationListStatuses.GRANTED}`
        );
        mutate(
          `/accreditation/list/${eventId}/${ApplicationListStatuses.OTHER}`
        );
        closeParentModal();
        onClose();
      }),
      {
        errorKeys: {
          AccreditationStatusChangeNotAllowed: t(
            "errorKeys.accreditationUpdateNotAllowed"
          ),
          AccreditationWrongType: t("errorKeys.accreditationWrongType"),
          AccreditationTypeLimitExceed: t(
            "errorKeys.accreditationTypeLimitExceed"
          ),
        },
      }
    );
  };

  let rejectedOrCanceledOption;

  if (
    !(
      status === AccreditationTypes.GRANTED ||
      status === AccreditationTypes.CANCELED
    )
  )
    rejectedOrCanceledOption = AccreditationTypes.REJECTED;

  if (
    !(
      status === AccreditationTypes.PENDING ||
      status === AccreditationTypes.REJECTED ||
      status === AccreditationTypes.INCOMPLETE
    )
  )
    rejectedOrCanceledOption = AccreditationTypes.CANCELED;

  const options = useMemo(
    () => [
      {
        value: AccreditationTypes.PENDING,
        label: (
          <AccreditationStatusButton
            status={AccreditationTypes.PENDING}
            isDisabled={status === AccreditationTypes.INCOMPLETE ? true : false}
          />
        ),
        isDisabled: status === AccreditationTypes.INCOMPLETE ? true : false,
      },
      {
        value: AccreditationTypes.GRANTED,
        label: (
          <AccreditationStatusButton status={AccreditationTypes.GRANTED} />
        ),
      },
      {
        value: AccreditationTypes.INCOMPLETE,
        label: (
          <AccreditationStatusButton status={AccreditationTypes.INCOMPLETE} />
        ),
      },
      {
        value: rejectedOrCanceledOption,
        label: <AccreditationStatusButton status={rejectedOrCanceledOption} />,
      },
    ],
    [status]
  );

  return (
    <>
      {status === AccreditationTypes.PENDING ? (
        <Flex align="center" width="140px">
          {grantedButton && (
            <IconButton
              aria-label="granted-button"
              icon={<CheckCircle />}
              mr={1}
              onClick={() =>
                openAccreditationPreview
                  ? accreditationModal.onOpen()
                  : onChangeStatus(AccreditationTypes.GRANTED)
              }
              {...colorSchemes("green")}
              size="sm"
              flex={1}
            />
          )}
          <IconButton
            aria-label="reject-button"
            icon={<XCircle />}
            onClick={() => onChangeStatus(AccreditationTypes.REJECTED)}
            {...colorSchemes("red")}
            size="sm"
            flex={1}
            mr={1}
          />
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="statuses"
              icon={<ChevronDown />}
              mr={1}
              size="sm"
            />
            {inTable ? (
              <Portal>
                <MenuList>
                  <MenuItem>
                    <AccreditationStatusButton
                      status={AccreditationTypes.GRANTED}
                      onClick={() => onChangeStatus(AccreditationTypes.GRANTED)}
                    />
                  </MenuItem>
                  <MenuItem>
                    <AccreditationStatusButton
                      status={AccreditationTypes.INCOMPLETE}
                      onClick={() =>
                        onChangeStatus(AccreditationTypes.INCOMPLETE)
                      }
                    />
                  </MenuItem>
                  <MenuItem>
                    <AccreditationStatusButton
                      status={AccreditationTypes.REJECTED}
                      onClick={() =>
                        onChangeStatus(AccreditationTypes.REJECTED)
                      }
                    />
                  </MenuItem>
                </MenuList>
              </Portal>
            ) : (
              <MenuList>
                <MenuItem>
                  <AccreditationStatusButton
                    status={AccreditationTypes.GRANTED}
                    onClick={() => {
                      onChangeStatus(AccreditationTypes.GRANTED);
                    }}
                  />
                </MenuItem>
                <MenuItem>
                  <AccreditationStatusButton
                    status={AccreditationTypes.INCOMPLETE}
                    onClick={() => {
                      onChangeStatus(AccreditationTypes.INCOMPLETE);
                    }}
                  />
                </MenuItem>
                <MenuItem>
                  <AccreditationStatusButton
                    status={AccreditationTypes.REJECTED}
                    onClick={() => {
                      onChangeStatus(AccreditationTypes.REJECTED);
                    }}
                  />
                </MenuItem>
              </MenuList>
            )}
          </Menu>
        </Flex>
      ) : (
        <Box width="180px">
          <SelectAdvanced
            options={options}
            value={options?.find((option) => selectedStatus === option?.value)}
            onChange={setSelectedStatus}
            isClearable={false}
            isSearchable={false}
            menuPortalTarget={inTable ? document.body : null}
          />
        </Box>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onEsc={() => {
          setSelectedStatus(status);
          onClose();
          closeParentModal();
        }}
        isCentered
        preserveScrollBarGap
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader d="flex" alignItems="center">
            {t("messageTo", {
              name: `${accreditationData?.firstName} ${accreditationData?.lastName}`,
            })}
            <FlagImage
              ml={2}
              mr={1}
              language={accreditationData?.user?.profile?.locale}
            />
            <HelpTooltip label="accreditation.message" />
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              setSelectedStatus(status);
              onClose();
              closeParentModal();
            }}
          />
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <ModalBody>
              <FormControl
                isInvalid={!!errors.message}
                isRequired={incompleteStatus}
              >
                <Textarea
                  {...register("message", {
                    required: incompleteStatus,
                  })}
                />
                {errors.message && (
                  <FormErrorMessage>
                    {t("users.fieldRequired")}
                  </FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              {!incompleteStatus && (
                <Button
                  mr={3}
                  onClick={() => {
                    updateStatus(modalSelectedStatus);
                    onClose();
                    closeParentModal();
                  }}
                  variant="ghost"
                >
                  {t("skip")}
                </Button>
              )}
              <Button isLoading={isSubmitting} type="submit">
                {t("send")}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      {accreditationModal.isOpen && (
        <AccreditationModal
          id={accreditationId}
          eventId={eventId}
          isOpen={accreditationModal.isOpen}
          onClose={accreditationModal.onClose}
        />
      )}
    </>
  );
};

export default AccreditationStatus;
