import React from "react";
import { Edit2 } from "react-feather";

import { EditorialOffice } from "@/types/office";
import StatusIcon from "@/components/common/StatusIcon";

const EditorialOfficeStatusIcon = ({
  editorialOffice,
}: {
  editorialOffice: EditorialOffice;
}): JSX.Element => {
  return (
    <>
      {editorialOffice?.name && !editorialOffice?.activated && (
        <StatusIcon
          status="editorialOffice.accreditationInformation.pendingStatus"
          icon={Edit2}
          color="red.500"
          _hover={{ color: "red.800" }}
        />
      )}
    </>
  );
};

export default EditorialOfficeStatusIcon;
